import React, {useState} from "react";
import * as styles from "./our-commitments.module.scss";
import Layout from "../../components/layout/layout";
import {graphql} from "gatsby";
import {OurCommitmentsQuery} from "../../../graphql-types";
import PageCover from "../../components/common/page-cover/page-cover";
import LinksGroupSide from "../../components/common/links-group-side/links-group-side";
import RichDatoCmsContent from "../../components/rich-dato-cms-content/rich-dato-cms-content";
import useWindowWidth from "../../hooks/use-window-width";

type ReactProps = {
    data: OurCommitmentsQuery
}

const OurCommitmentsPage:React.FC<ReactProps> = ({data}) => {

    const isMobile = useWindowWidth() <= 1200;
    const [selectors, setSelectors] = useState(
        [{id: 1, title: data.datoCmsEngagementsNew.mainPageButtonTitle, content: data.datoCmsEngagementsNew.mainContent, selected: true, link: "/engagements"},
            {id: 2, title: data.datoCmsEngagementsNew.securiteButtonTitle, content: data.datoCmsEngagementsNew.securiteContent, selected: false, link: "/engagements/securite"},
            {id: 3, title: data.datoCmsEngagementsNew.accessibiliteButtonTitle, content: data.datoCmsEngagementsNew.accessibiliteContent, selected: false, link: "/engagements/accessibilite"},
            {id: 4, title: data.datoCmsEngagementsNew.satisfactionButtonTitle, content: data.datoCmsEngagementsNew.satisfactionContent, selected: false, link: "/engagements/satisfaction"},
            {id: 5, title: data.datoCmsEngagementsNew.tarifsButtonTitle, content: data.datoCmsEngagementsNew.tarifsContent, selected: false, link: "/engagements/tarifs"},
        ]);
    const [sContent, setScontent] = useState(selectors[0].content)

    return (
        <Layout data={data.datoCmsFooter} stickyButtonsData={data.datoCmsWebStickyButton} chatbotData={data.datoCmsChatbot} seo={data.datoCmsEngagementsNew.seo}         pageTemplate="our commitements">
            <PageCover
                title={data.datoCmsEngagementsNew.cover[0] && data.datoCmsEngagementsNew.cover[0].title}
                image={data.datoCmsEngagementsNew.cover[0] && data.datoCmsEngagementsNew.cover[0].backgroundImage}
            />
            <section className={styles.pageContainer}>
                <div className={styles.pageContent}>
                    {!isMobile && <LinksGroupSide data={data.datoCmsEngagementsNew.linksGroup} />}
                    <div className={styles.content}>
                        <div className={styles.buttonsContainer}>
                            {selectors.map((selector, index) => {
                                return (
                                    <a
                                        aria-label={selector.title}
                                        key={selector.id}
                                        className={selector.selected ? styles.buttonSelected : styles.button}
                                        href={selector.link}
                                    >
                                        {selector.title}
                                    </a>
                                )
                            })}
                        </div>
                        {sContent && <RichDatoCmsContent data={sContent} />}
                    </div>
                    {isMobile && <LinksGroupSide data={data.datoCmsEngagementsNew.linksGroup} />}
                </div>
            </section>
        </Layout>
    )

}

export const fragment = graphql`
    fragment EngagementFields on DatoCmsEngagementsNew {
        seo {
          description
          title
        }
        cover {
            title
            backgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        linksGroup {
            id
            title
            titleAttribute
            slug
            mainColor {
                hex
            }       
            icon {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            iconHover {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            backgroundIcon {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            backgroundIconHover {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        mainContent {
            __typename
            ... on DatoCmsTitle {
                ...TitleFields
            }
            ... on DatoCmsParagraph {
                ...ParagraphFields
            }
            ... on DatoCmsSubtitle {
                ...SubtitleFields
            }
            ... on DatoCmsPicturedParagraph {
                ...PicturedParagraphFields
            }
            ... on DatoCmsDetailedOutlinedBloc {
                ...DetailedOutlinedBlockFields
            }
            ... on DatoCmsOutlinedBloc {
                ...OutlinedBlockFields
            }
            ...on DatoCmsTitleParagraphBlueBloc {
                ...TitleParagraphBlueBlockFields
            }
        }
        mainPageButtonTitle
        securitySeo {
          description
          title
        }
        securityPageCover {
            title
            backgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        securiteButtonTitle
        securiteContent {
            __typename
            ... on DatoCmsTitle {
                ...TitleFields
            }
            ... on DatoCmsParagraph {
                ...ParagraphFields
            }
            ... on DatoCmsSubtitle {
                ...SubtitleFields
            }
            ... on DatoCmsPicturedParagraph {
                ...PicturedParagraphFields
            }
            ... on DatoCmsDetailedOutlinedBloc {
                ...DetailedOutlinedBlockFields
            }
            ... on DatoCmsOutlinedBloc {
                ...OutlinedBlockFields
            }
            ...on DatoCmsTitleParagraphBlueBloc {
                ...TitleParagraphBlueBlockFields
            }
        }
        accessibilitySeo {
          description
          title
        }
        accessibilityPageCover {
            title
            backgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        accessibiliteButtonTitle
        accessibiliteContent {
            __typename
            ... on DatoCmsTitle {
                ...TitleFields
            }
            ... on DatoCmsParagraph {
                ...ParagraphFields
            }
            ... on DatoCmsSubtitle {
                ...SubtitleFields
            }
            ... on DatoCmsPicturedParagraph {
                ...PicturedParagraphFields
            }
            ... on DatoCmsDetailedOutlinedBloc {
                ...DetailedOutlinedBlockFields
            }
            ... on DatoCmsOutlinedBloc {
                ...OutlinedBlockFields
            }
            ...on DatoCmsTitleParagraphBlueBloc {
                ...TitleParagraphBlueBlockFields
            }
        }
        satisfactionSeo {
          description
          title
        }
        satisfactionPageCover {
            title
            backgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        satisfactionButtonTitle
        satisfactionContent {
            __typename
            ... on DatoCmsTitle {
                ...TitleFields
            }
            ... on DatoCmsParagraph {
                ...ParagraphFields
            }
            ... on DatoCmsSubtitle {
                ...SubtitleFields
            }
            ... on DatoCmsPicturedParagraph {
                ...PicturedParagraphFields
            }
            ... on DatoCmsDetailedOutlinedBloc {
                ...DetailedOutlinedBlockFields
            }
            ... on DatoCmsOutlinedBloc {
                ...OutlinedBlockFields
            }
            ...on DatoCmsTitleParagraphBlueBloc {
                ...TitleParagraphBlueBlockFields
            }
        }
        tarifsSeo {
          description
          title
        }
        tarifsPageCover {
            title
            backgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        tarifsButtonTitle
        tarifsContent {
            __typename
            ... on DatoCmsTitle {
                ...TitleFields
            }
            ... on DatoCmsParagraph {
                ...ParagraphFields
            }
            ... on DatoCmsSubtitle {
                ...SubtitleFields
            }
            ... on DatoCmsPicturedParagraph {
                ...PicturedParagraphFields
            }
            ... on DatoCmsDetailedOutlinedBloc {
                ...DetailedOutlinedBlockFields
            }
            ... on DatoCmsOutlinedBloc {
                ...OutlinedBlockFields
            }
            ...on DatoCmsTitleParagraphBlueBloc {
                ...TitleParagraphBlueBlockFields
            }
        }
    }
`

export const pageQuery = graphql`
    query OurCommitments($locale: String!) {  
        datoCmsFooter(locale: {  eq: $locale }) {
            ...FooterFields
        }
        datoCmsEngagementsNew(locale: { eq: $locale }) {
            ...EngagementFields
        }
        datoCmsWebStickyButton(locale: {  eq: $locale }) {
            ...StickyButtonsFields
        }
        datoCmsChatbot(locale: { eq: $locale }) {
            ...ChatbotFields
        }
    }
`


export default OurCommitmentsPage;